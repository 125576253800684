<template>
  <div>
    <div class="session">
      <div class="title bb mb16">年度总结</div>
      <div class="conclusion-box clearfix">
        <div class="item-box fl">
          <div class="haodl-total fl-c-b dian-bg">
            <span>耗电量</span>
            <span class="black fs24"
              >2,000,000
              <span class="fs12"
                >kw·h <span class="green fs14">2.23% <img src="../../../assets/img/brainDecision/main_icon_rise_normal.png.png" alt="" /></span></span
            ></span>
          </div>
          <div>
            <div class="data-item">
              <p>人均用电量为<span class="fs20 blue">1863.29</span>千瓦时</p>
              <p>人均用电量同比<span class="red">↓</span><span class="fs20 red">13.85%</span></p>
            </div>
            <div class="data-item">
              <p>单位面积用电量为<span class="fs20 blue">1863.29</span>千瓦时</p>
              <p>单位面积用电量同比<span class="green">↑</span><span class="fs20 green">13.85%</span></p>
            </div>
            <div class="data-item">
              <p>费用<span class="fs20 blue">200</span>万，同比<span class="red">↓</span><span class="fs20 red">10.63%</span></p>
            </div>
          </div>
        </div>
        <div class="item-box fl">
          <div class="haodl-total fl-c-b shui-bg">
            <span>用水量</span>
            <span class="black fs24"
              >10,000,000<span class="fs12">m³</span>
              <span class="fs14 green">2.23% <img src="../../../assets/img/brainDecision/main_icon_rise_normal.png.png" alt="" /></span
            ></span>
          </div>
          <div>
            <div class="data-item">
              <p>人均用水量为<span class="fs20 blue">1863.29</span>吨</p>
              <p>人均用水量同比<span class="red">↓</span><span class="fs20 red">13.85%</span></p>
            </div>
            <div class="data-item">
              <p>单位面积用水量为<span class="fs20 blue">1863.29</span>吨</p>
              <p>单位面积用水量同比<span class="green">↑</span><span class="fs20 green">13.85%</span></p>
            </div>
            <div class="data-item">
              <p>费用<span class="fs20 blue">200</span>万，同比<span class="red">↓</span><span class="fs20 red">10.63%</span></p>
            </div>
          </div>
        </div>
        <div class="item-box fl" style="margin-right: 0">
          <div class="haodl-total fl-c-b meiqi-bg">
            <span>煤气消耗量</span>
            <span class="black fs24"
              >10,000,000<span class="fs12">m³</span
              ><span class="fs14 green">2.23% <img src="../../../assets/img/brainDecision/main_icon_rise_normal.png.png" alt="" /></span
            ></span>
          </div>
          <div>
            <div class="data-item">
              <p>人均用消耗量为<span class="fs20 blue">1863.29</span>吨</p>
              <p>人均用消耗量量同比<span class="red">↓</span><span class="fs20 red">13.85%</span></p>
            </div>
            <div class="data-item">
              <p>单位面积用消耗量量为<span class="fs20 blue">1863.29</span>吨</p>
              <p>单位面积用消耗量量同比<span class="green">↑</span><span class="fs20 green">13.85%</span></p>
            </div>
            <div class="data-item">
              <p>费用<span class="fs20 blue">200</span>万，同比<span class="red">↓</span><span class="fs20 red">10.63%</span></p>
            </div>
          </div>
        </div>
        <div class="conclusion mb16">总结</div>
        <div style="color: #333">经分析，<span class="blue">2019</span>年能源资源消耗呈现“<span class="blue">有升有降，总体下降</span>”的趋势。</div>
      </div>
    </div>
    <div class="session">
      <div class="title bb mb16">所选时段统计分析</div>
      <div class="p024">
        <div class="bg-title bold fl-c pl20 mb16">
          <span class="mr16">耗电量分析</span>
          <span class="green">2.23%</span>
          <img src="../../../assets/img/brainDecision/main_icon_rise_normal.png.png" alt="" />
        </div>
        <div class="fl-c pl20 mb24">
          <span class="black mr16">耗电用途</span>
          <div class="tag-item blue mr16">空调</div>
          <div class="tag-item blue mr16">动力设备</div>
          <div class="tag-item blue mr16">汽车充电桩</div>
          <div class="tag-item blue mr16">其他用电</div>
          <div class="tag-item blue">办公用电</div>
        </div>
        <div class="fl-box h279 mb30">
          <div class="chart-box mr40">
            <v-chart autoresize :options="barOption('车间用电量分析')" style="height: 100%; width: 100%" />
          </div>
          <div class="chart-box fl-box">
            <div class="chart-box chart-box-bg mr40">
              <v-chart autoresize :options="getPreOption(pieData1, '耗电分项')" style="height: 100%; width: 100%" />
            </div>
            <div class="chart-box chart-box-bg">
              <v-chart autoresize :options="getPreOption(pieData2, '日夜间耗电情况')" style="height: 100%; width: 100%" />
            </div>
          </div>
        </div>
        <div class="fl-box h279 mb30">
          <div class="chart-box mr40">
            <v-chart autoresize :options="lineOption1('耗电量趋势折线图')" style="height: 100%; width: 100%" />
          </div>
          <div class="chart-box chart-box-bg conclusion-advice">
            <div class="conclusion mb4">总结</div>
            <div class="mb30">
              排名<span class="blue bold">前三</span>的用电部门：<span class="blue bold">1号车间、2号车间、3号车间</span>；<br />
              <span class="blue bold">4-6月、11-12月</span>
              “用电量明显高于平均水平；<br />
              空调耗电最多。
            </div>
            <div class="advice mb4">建议</div>
            <div>
              1、推进节能新技术、新方法。更换节能产品、淘汰高耗能设备<br />
              2、分时段供暖、人走关灯。
            </div>
          </div>
        </div>
        <div class="bg-title bold fl-c pl20 mb16">
          <span class="mr16">用水量分析</span>
          <span class="green">2.23%</span>
          <img src="../../../assets/img/brainDecision/main_icon_rise_normal.png.png" alt="" />
        </div>
        <div class="fl-box mb16">
          <div class="charts-card-item mr16">
            <p class="mt16 mb4">生产用水量</p>
            <p class="mb4"><span class="fs24 black">2,000,000</span><span class="fs12">m³</span></p>
            <p>
              <span class="green"> 2.23% <img src="../../../assets/img/brainDecision/main_icon_rise_normal.png.png" alt="" /> </span>
            </p>
          </div>
          <div class="charts-card-item mr16">
            <p class="mt16 mb4">锅炉用水量</p>
            <p class="mb4"><span class="fs24 black">2,000,000</span><span class="fs12">m³</span></p>
            <p>
              <span class="red"> 2.23% <img src="../../../assets/img/brainDecision/main_icon_fall_normal.png.png" alt="" /> </span>
            </p>
          </div>
          <div class="charts-card-item mr16">
            <p class="fs12 mt18 mb4">制冷及空调系统循环补水量</p>
            <p class="mb4"><span class="fs24 black">10,000,000</span><span class="fs12">m³</span></p>
            <p>
              <span class="green"> 2.23% <img src="../../../assets/img/brainDecision/main_icon_rise_normal.png.png" alt="" /> </span>
            </p>
          </div>
          <div class="charts-card-item mr16">
            <p class="mt16 mb4">生活用水量</p>
            <p class="mb4"><span class="fs24 black">2,000,000</span><span class="fs12">m³</span></p>
            <p>
              <span class="green"> 2.23% <img src="../../../assets/img/brainDecision/main_icon_rise_normal.png.png" alt="" /> </span>
            </p>
          </div>
          <div class="charts-card-item mr16">
            <p class="mt16 mb4">其他水用水量</p>
            <p class="mb4"><span class="fs24 black">2,000,000</span><span class="fs12">m³</span></p>
            <p>
              <span class="green"> 2.23% <img src="../../../assets/img/brainDecision/main_icon_rise_normal.png.png" alt="" /> </span>
            </p>
          </div>
          <div class="charts-card-item">
            <p class="mt16 mb4">当前库存</p>
            <p class="mb4"><span class="fs24 black">2,000,000</span><span class="fs12">m³</span></p>
          </div>
        </div>
        <div class="fl-box mb30">
          <div class="water-item mr16">
            <v-chart autoresize :options="barOption('车间用水量分析')" style="height: 100%; width: 100%" />
          </div>
          <div class="water-item fl-box">
            <div class="water-item-card mr16">重复用水企业</div>
            <div class="water-item-card mr16">取水成本费用：20万元</div>
            <div class="water-item-card">水利用率89.22%</div>
          </div>
        </div>
        <div class="fl-box h279 mb30">
          <div class="chart-box mr40">
            <v-chart autoresize :options="lineOption1('耗水量趋势折线图')" style="height: 100%; width: 100%" />
          </div>
          <div class="chart-box chart-box-bg conclusion-advice">
            <div class="conclusion mb4">总结</div>
            <div class="mb30">
              用水量大，水资源利用率偏低<br />
              企业为重复用水企业，能较好的节约成本<br />
              车间水量比同行业其他企业用水量少，节能减排效果明显
            </div>
            <div class="advice mb4">建议</div>
            <div>
              设备升级，提高水资源利用率<br />
              接入重复用水技术，节约成本<br />
              提升污染处理力度，实现水资源的可持续利用。
            </div>
          </div>
        </div>
        <div class="bg-title bold fl-c pl20 mb16">
          <span class="mr16">煤气消耗量分析</span>
          <span class="green">2.23%</span>
          <img src="../../../assets/img/brainDecision/main_icon_rise_normal.png.png" alt="" />
        </div>
        <div class="fl-box h279 mb30">
          <div class="chart-box mr40">
            <v-chart autoresize :options="barOption('车间煤气使用量分析')" style="height: 100%; width: 100%" />
          </div>
          <div class="chart-box">
            <v-chart autoresize :options="lineOption1('耗煤气量趋势折线图')" style="height: 100%; width: 100%" />
          </div>
        </div>
        <div class="fl-box h112 mb30">
          <div class="chart-box chart-box-bg conclusion-advice mr40">
            <div class="conclusion mb4">总结</div>
            <div>
              用水量大，水资源利用率偏低<br />
              企业为重复用水企业，能较好的节约成本<br />
              车间水量比同行业其他企业用水量少，节能减排效果明显
            </div>
          </div>
          <div class="chart-box chart-box-bg conclusion-advice">
            <div class="conclusion mb4">建议</div>
            <div>设备升级，提高水资源利用率 接入重复用水技术，节约成本 提升污染处理力度，实现水资源的可持续利用。</div>
          </div>
        </div>
        <div class="bg-title bold fl-c pl20 mb16">污染物概览</div>
        <div class="fl-box">
          <div class="statistical fl-box mr40">
            <div class="mr16" style="width: calc((100% - 16px) * 0.5)">
              <div class="statistical-item">
                <div class="statistical-header">污水产生量</div>
                <div class="fl-box-b" style="margin-top: 12px">
                  <div>未治理</div>
                  <div>已治理</div>
                </div>
                <div class="fl-box-b" style="margin: 2px 0 4px">
                  <div class="red">10000吨</div>
                  <div class="blue"><span class="bold">20000</span>吨</div>
                  <div class="green">10000吨</div>
                </div>
                <div class="statistical-progress">
                  <div class="left" style="width: calc((100% - 2px) * 0.6)"></div>
                  <div class="line"></div>
                  <div class="right" style="width: calc((100% - 2px) * 0.4)"></div>
                </div>
              </div>
              <div class="statistical-item">
                <div class="statistical-header">固体废弃物量</div>
                <div class="fl-box-b" style="margin-top: 12px">
                  <div>未治理</div>
                  <div>已治理</div>
                </div>
                <div class="fl-box-b" style="margin: 2px 0 4px">
                  <div class="red">10000吨</div>
                  <div class="blue"><span class="bold">20000</span>方</div>
                  <div class="green">10000吨</div>
                </div>
                <div class="statistical-progress">
                  <div class="left" style="width: calc((100% - 2px) * 0.5)"></div>
                  <div class="line"></div>
                  <div class="right" style="width: calc((100% - 2px) * 0.5)"></div>
                </div>
              </div>
              <div class="statistical-item">
                <div class="statistical-header">气排放量</div>
                <div class="fl-box-b" style="margin-top: 12px">
                  <div>未治理</div>
                  <div>已治理</div>
                </div>
                <div class="fl-box-b" style="margin: 2px 0 4px">
                  <div class="red">10000吨</div>
                  <div class="blue"><span class="bold">20000</span>方</div>
                  <div class="green">10000吨</div>
                </div>
                <div class="statistical-progress">
                  <div class="left" style="width: calc((100% - 2px) * 0.7)"></div>
                  <div class="line"></div>
                  <div class="right" style="width: calc((100% - 2px) * 0.3)"></div>
                </div>
              </div>
            </div>
            <div style="width: calc((100% - 16px) * 0.5)">
              <div class="statistical-item fl-box-b">
                <div>
                  日均噪音(治理后) <span class="blue" style="margin-left: 6px"><span class="bold fs16">65</span><span class="fs12">db</span> </span>
                </div>
              </div>
              <div class="statistical-item fl-box-b">
                <div>
                  辐射量<span class="blue" style="margin-left: 6px"><span class="bold fs16">4000</span><span class="fs12">gray</span></span>
                </div>
                <div>
                  日均辐射量(治理后)<span class="blue" style="margin-left: 6px"><span class="bold fs16">200</span></span>
                </div>
              </div>
              <div class="statistical-item fl-box-b">
                <div>
                  治理费用 <span class="blue" style="margin-left: 6px"><span class="bold fs16">50</span><span class="fs12">万</span> </span>
                </div>
              </div>
            </div>
          </div>
          <div class="chart-text">
            <div class="conclusion mb4">总结</div>
            <p class="mb30">污染物治理率达到<span class="bold blue">82.36%，治理力度一般</span>治理成本为<span class="bold blue">50</span>万，费用较高</p>
            <div class="advice mb4">建议</div>
            <p>提升治理技术，节约治理成本</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      barData1: [
        { name: '1号车间', value: 50 },
        { name: '2号车间', value: 59 },
        { name: '3号车间', value: 70 },
        { name: '4号车间', value: 51 },
        { name: '5号车间', value: 60 },
        { name: '6号车间', value: 68 },
        { name: '7号车间', value: 75 },
        { name: '8号车间', value: 50 },
      ],
      pieData1: [
        {
          name: '空调',
          value: 40,
        },
        {
          name: '动力',
          value: 30,
        },
        {
          name: '照明',
          value: 20,
        },
        {
          name: '其它',
          value: 10,
        },
      ],
      pieData2: [
        {
          name: '日间',
          value: 40,
        },
        {
          name: '夜间',
          value: 60,
        },
      ],
      lineData1: [
        {
          name: '11.01',
          value1: 100,
        },
        {
          name: '11.05',
          value1: 138,
        },
        {
          name: '11.10',
          value1: 350,
        },
        {
          name: '11.15',
          value1: 173,
        },
        {
          name: '11.20',
          value1: 180,
        },
        {
          name: '11.25',
          value1: 150,
        },
        {
          name: '11.30',
          value1: 180,
        },
      ],
    };
  },
  computed: {
    
  },
  methods: {
    barOption(text) {
      var data = this.barData1;
      var _labels = data.map((v) => {
        return v.name;
      });
      return {
        title: {
          text,
          textStyle: {
            fontSize: 14,
          },
        },
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          top: '50',
          left: '0',
          right: '0',
          bottom: '0',
          containLabel: true,
        },
        // x轴
        xAxis: {
          type: 'category',
          axisLine: {
            lineStyle: {
              color: 'rgba(33,33,33,0.2)',
              width: 1,
              type: 'solid',
            },
          },
          axisLabel: {
            // 坐标轴标签
            show: true, // 是否显示
            color: '#666', // 默认取轴线的颜色
          },
          axisTick: {
            //刻度线
            show: false,
          },
          data: _labels,
        },
        yAxis: {
          axisLine: {
            show: false,
          },
          axisLabel: {
            // 坐标轴标签
            show: true, // 是否显示
            color: '#666', // 默认取轴线的颜色
          },
          axisTick: {
            //刻度线
            show: false,
          },
          splitLine: {
            show: true,
            lineStyle: {
              type: 'dashed',
            },
          },
        },
        // 数据
        series: [
          {
            type: 'bar',
            barWidth: 20,
            itemStyle: {
              normal: {
                color: '#255ADA',
              },
            },
            data: data,
          },
        ],
      };
    },
    lineOption1(text) {
      let data = this.lineData1;
      let color = ['#0090FF', '#36CE9E', '#FFC005', '#FF515A', '#8B5CFF', '#00CA69'];
      let xAxisData = data.map((v) => v.name);
      let yAxisData1 = data.map((v) => v.value1);
      const hexToRgba = (hex, opacity) => {
        let rgbaColor = '';
        let reg = /^#[\da-f]{6}$/i;
        if (reg.test(hex)) {
          rgbaColor = `rgba(${parseInt('0x' + hex.slice(1, 3))},${parseInt('0x' + hex.slice(3, 5))},${parseInt('0x' + hex.slice(5, 7))},${opacity})`;
        }
        return rgbaColor;
      };
      return {
        color: color,
        title: {
          text,
          textStyle: {
            fontSize: 14,
          },
        },
        grid: {
          top: '50',
          left: '0',
          right: '0',
          bottom: '0',
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            let html = '';
            params.forEach((v) => {
              console.log(v);
              html += `<div style="color: #666;font-size: 14px;line-height: 24px">
                      <span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                        color[v.componentIndex]
                      };"></span>
                      ${v.seriesName}.${v.name}
                      <span style="color:${color[v.componentIndex]};font-weight:700;font-size: 18px">${v.value}</span>
                      万元`;
            });

            return html;
          },
          extraCssText: 'background: #fff; border-radius: 0;box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);color: #333;',
          axisPointer: {
            type: 'shadow',
            shadowStyle: {
              color: '#ffffff',
              shadowColor: 'rgba(225,225,225,1)',
              shadowBlur: 5,
            },
          },
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            axisLabel: {
              textStyle: {
                color: '#333',
              },
            },
            axisLine: {
              lineStyle: {
                color: '#D9D9D9',
              },
            },
            axisTick: {
              //刻度线
              show: false,
            },
            data: xAxisData,
          },
        ],
        yAxis: [
          {
            type: 'value',
            axisLabel: {
              textStyle: {
                color: '#666',
              },
            },
            nameTextStyle: {
              color: '#666',
              fontSize: 12,
              lineHeight: 40,
            },
            splitLine: {
              lineStyle: {
                type: 'dashed',
                color: '#E9E9E9',
              },
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
          },
        ],
        series: [
          {
            name: '11号车间',
            type: 'line',
            smooth: true,
            symbol: 'none', //取消折点圆圈
            symbolSize: 8,
            zlevel: 3,
            lineStyle: {
              normal: {
                color: '#2270DE',
              },
            },
            areaStyle: {
              normal: {
                color: new this.$echarts.graphic.LinearGradient(
                  0,
                  0,
                  0,
                  1,
                  [
                    {
                      offset: 0,
                      color: 'rgba(37,90,218,0.45)',
                    },
                    {
                      offset: 1,
                      color: 'rgba(37,90,218,0.00)',
                    },
                  ],
                  false
                ),
                shadowColor: hexToRgba(color[0], 0.1),
                shadowBlur: 10,
              },
            },
            data: yAxisData1,
          },
        ],
      };
    },
    getPreOption(data, text) {
      return {
        title: {
          text: text,
          left: 16,
          textStyle: {
            fontSize: 14,
          },
        },
        color: ['#255ADA', '#14C53E', '#ED7A2C', '#E22132'],
        tooltip: {
          trigger: 'item',
          formatter: '{a}：<br/>{b} : {c}({d}%)',
        },
        legend: {
          icon: 'circle',
          bottom: 46,
          itemWidth: 8,
          itemHeight: 8,
          orient: 'horizontal',
          itemGap: 16,
          textStyle: {
            fontSize: 14,
            color: '#666',
          },
          data: data.map((item) => {
            return item.name;
          }),
        },
        series: [
          {
            name: '分布',
            type: 'pie',
            radius: [0, 60],
            center: ['50%', '50%'],
            data: data,
            label: {
              position: 'inner',
              fontSize: 16,
              show: false,
            },
            itemStyle: {
              normal: {
                label: {
                  show: false,
                },
                labelLine: {
                  show: false,
                },
                borderWidth: 1,
                borderColor: '#fff',
              },
              emphasis: {
                shadowBlur: 50,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.conclusion-box {
  width: 100%;
  margin-bottom: 30px;

  .item-box {
    width: calc((100% - 32px) / 3);
    margin-right: 16px;
    color: #333;
    .haodl-total {
      height: 86px;
      border-radius: 4px 4px 0 0;
      padding: 16px;
    }
    .dian-bg {
      background: url('../../../assets/img/brainDecision/yqhnfx_icon1.png') 15px center no-repeat, linear-gradient(180deg, #cce6ff 0%, #ffffff 100%);
    }

    .shui-bg {
      background: url('../../../assets/img/brainDecision/yqhnfx_icon2.png') 15px center no-repeat, linear-gradient(180deg, #cce6ff 0%, #ffffff 100%);
    }

    .meiqi-bg {
      background: url('../../../assets/img/brainDecision/yqhnfx_icon3.png') 15px center no-repeat, linear-gradient(180deg, #cce6ff 0%, #ffffff 100%);
    }
    .data-item {
      margin-bottom: 20px;
      padding-left: 16px;
      p {
        line-height: 24px;
        margin-bottom: 8px;
      }
    }
  }
}
.bg-title {
  background: #f3f3f5;
  height: 48px;
  color: #666666;
}
.tag-item {
  background: rgba(37, 90, 218, 0.2);
  border-radius: 4px;
  line-height: 24px;
  text-align: center;
  width: 96px;
}
.chart-box {
  width: calc((100% - 40px) / 2);
  height: 100%;
}
.chart-box-bg {
  background-image: linear-gradient(180deg, #ffffff 0%, #eff7ff 100%);
}
.conclusion-advice {
  padding-left: 16px;
  font-size: 14px;
  color: #333333;
  line-height: 22px;
}
.charts-card-item {
  background-image: linear-gradient(180deg, #cce6ff 0%, #ffffff 100%);
  border-radius: 4px 4px 0 0;
  width: calc((100% - 80px) / 6);
  height: 106px;
  text-align: center;
  color: #333;
}
.water-item {
  width: calc((100% - 16px) / 2);
  height: 238px;
  &:nth-of-type(1) {
    padding-right: 12px;
  }
  .water-item-card {
    width: calc((100% - 32px) / 3);
    height: 100%;
    border-radius: 4px;
    text-align: center;
    color: #333333;
    line-height: 238px;
    &:nth-of-type(1) {
      background: url('../../../assets/img/brainDecision/cyhnfx_ysl_img1.png') 0 center no-repeat, #eff7ff;
    }
    &:nth-of-type(2) {
      background: url('../../../assets/img/brainDecision/cyhnfx_ysl_img2.png') 0 center no-repeat, #eff7ff;
    }
    &:nth-last-of-type(1) {
      background: url('../../../assets/img/brainDecision/cyhnfx_ysl_img3.png') 0 center no-repeat, #eff7ff;
    }
  }
}
.chart-text {
  width: 260px;
  color: #333333;
  line-height: 22px;
}
.statistical {
  width: calc(100% - 300px);
  .statistical-title {
    font-size: 14px;
    color: #000000;
    line-height: 20px;
    margin: 16px 0;
  }

  .statistical-item {
    background-image: linear-gradient(269deg, #f7fbff 0%, #eff7ff 100%);
    border: 1px solid #0e459c;
    border-radius: 4px;
    margin-bottom: 16px;
    height: 68px;
    padding: 0 16px;
    position: relative;
    font-size: 14px;
    line-height: 14px;
    color: #333333;
  }

  .fl-box-b {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .statistical-header {
    width: 120px;
    height: 24px;
    font-size: 12px;
    color: #ffffff;
    text-align: center;
    line-height: 24px;
    font-weight: 600;
    background: url(../../../assets/img/brainDecision/yqhnfx_boxtop.png) center no-repeat;
    position: absolute;
    top: 0;
    left: 50%;
    margin-left: -60px;
  }

  .statistical-progress {
    border-radius: 4px;
    overflow: hidden;
    height: 12px;
    display: flex;
  }

  .statistical-progress .line {
    width: 2px;
    height: 100%;
  }

  .statistical-progress .left {
    height: 100%;
    opacity: 0.75;
    background: #e22132;
  }

  .statistical-progress .right {
    height: 100%;
    opacity: 0.75;
    background: #14c53e;
  }
}
</style>
