<template>
  <div>
    <div class="session mb16">
      <div class="title bb mb30">公司赋能前后对比</div>
      <div class="fl-box">
        <div class="BeforeAfter fl-box">
          <v-chart class="before-after-chart" autoresize :options="beforeChartOption" />
          <div class="compared-label">
            <div class="compared-item">营业收入</div>
            <div class="compared-item">净利润</div>
            <div class="compared-item">年产量</div>
          </div>
          <v-chart class="before-after-chart" autoresize :options="afterChartOption" />
          <!-- <v-chart class="before-after-chart" autoresize :options="afterChartOption" style="height: 100%; width: 100%" /> -->
        </div>
        <div class="compared-info compared-text fs12">
          <div class="mb16">
            行业内赋能后的企业：<br />
            营业收入平均增长<span class="blue fs16">500万</span>，平均增幅<span class="blue fs16">2.23%</span>。<br />
            净利润平均增长<span class="blue fs16">300万</span>，平均增幅<span class="blue fs16">2.23%</span>。<br />
            年产量平均增长<span class="blue fs16">1.20吨</span>，平均增幅<span class="blue fs16">2.23%</span>。<br />
          </div>
          <div>
            同行业未赋能的企业与赋能后的企业相比：<br />
            平均营业额减少<span class="blue fs16">200万</span>。<br />
            平均净利润减少<span class="blue fs16">200万</span>。<br />
            平均年产量减少<span class="blue fs16">1.20吨</span>。<br />
          </div>
        </div>
      </div>
    </div>
    <div class="session mb16">
      <div class="title bb mb30">区内经营行业全景表（近10年）</div>
      <div class="p024">
        <div class="table-box table-no-border mb20">
          <el-table :data="table1" border>
            <el-table-column label="2019年营收" prop="column1" align="center"></el-table-column>
            <el-table-column label="2014年营收" prop="column2" align="center"></el-table-column>
            <el-table-column :label="`2年\n增长率（%）`" prop="column3" align="center"></el-table-column>
            <el-table-column :label="'2年\n复合增长率（%）'" prop="column4" align="center"></el-table-column>
            <el-table-column label="2019年净利润" prop="column5" align="center"></el-table-column>
            <el-table-column label="2014年净利润" prop="column6" align="center"></el-table-column>
            <el-table-column :label="`2年\n增长率（%）`" prop="column7" align="center"></el-table-column>
            <el-table-column :label="'2年\n复合增长率（%）'" prop="column8" align="center"></el-table-column>
          </el-table>
        </div>
        <div class="compared-text fs12">
          区内<span class="blue fs16">应急行业</span>共<span class="blue fs16">五</span>家企业：从<span class="blue fs16">2019</span>年到<span class="blue fs16"
            >2021</span
          >年期间：<br />
          总营业收入：从<span class="blue fs16">10.5236</span>亿元增加到<span class="blue fs16">15.3546</span>亿元，<span class="blue fs16">2</span
          >年时间内增长了<span class="blue fs16">30.35%</span>，复合增长率为<span class="blue fs16">10.35%</span>；<br />
          净利润：从<span class="blue fs16">1.5236</span>亿元增加到<span class="blue fs16">2.3654</span>亿元，<span class="blue fs16">2</span>年增长了<span
            class="blue fs16"
            >141.93%</span
          >，复合增长率为<span class="blue fs16">15.86%</span>。<br />
        </div>
      </div>
    </div>
    <div class="session mb16">
      <div class="title bb mb24">2019-2021年公司营业收入、净利润增长率分析</div>
      <div class="p024">
        <div class="table-title bold">营收、净利润对比分析</div>
        <div class="table-box table-no-border mb24">
          <el-table :data="table2" border>
            <el-table-column label="公司名称" prop="column1" align="center"></el-table-column>
            <el-table-column label="营收对比" align="center">
              <el-table-column label="2019年营收" prop="column2" align="center"></el-table-column>
              <el-table-column label="2021年营收" prop="column3" align="center"></el-table-column>
              <el-table-column :label="`2年增长率\n（%）`" prop="column4" align="center"></el-table-column>
              <el-table-column :label="'2年复合增长率\n（%）'" prop="column5" align="center"></el-table-column>
            </el-table-column>
            <el-table-column label="净利润对比" align="center">
              <el-table-column label="2019年营收" prop="column6" align="center"></el-table-column>
              <el-table-column label="2021年营收" prop="column7" align="center"></el-table-column>
              <el-table-column :label="`2年增长率\n（%）`" prop="column8" align="center"></el-table-column>
              <el-table-column :label="'2年复合增长率\n（%）'" prop="column9" align="center"></el-table-column>
            </el-table-column>
          </el-table>
        </div>
        <div class="table-title bold mb24">营收、净利润增长率分析</div>
        <v-chart autoresize :options="growthOption" style="height: 200px; width: 900px" />
      </div>
    </div>
    <div class="session mb16">
      <div class="title bb">公司规模</div>
      <div class="scale-box fl-c-c">
        <div class="fl-c">
          <div class="scale-tiem fs12">
            <div class="bold"><span class="fs21">653</span>人</div>
            <div class="scale-lable">员工数</div>
          </div>
          <div class="scale-tiem fs12">
            <div class="bold"><span class="fs21">400</span>平方米</div>
            <div class="scale-lable">园区占地面积</div>
          </div>
          <div class="scale-tiem fs12">
            <div class="bold fs21">是</div>
            <div class="scale-lable">是否上市</div>
          </div>
        </div>
        <div class="business-box">
          <div class="business-header"><span class="bold">主营业务</span>：蓄电池、应急灯、工业用电</div>
          <div class="business-body">
            <div class="business-item fl-c-b">
              <div>当前行业内综合排名：<span class="fs21 blue bold">4</span></div>
              <div>比上一期浮动：<img src="../../../assets/img/brainDecision/main_icon_up_normal.png.png" alt="" /><span class="fs21 blue bold">5</span></div>
            </div>
            <div class="business-item fl-c-b">
              <div>市场份额：占比<span class="fs21 blue bold">2.33%</span></div>
              <div class="fl-c-b" style="width: 130px">
                客户满意度：<span class="fs21 blue bold">9<span class="fs12">分</span></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="session mb16">
      <div class="title bb">公司排污/治理情况</div>
      <div class="sewage-box fl-c-c">
        <v-chart autoresize :options="scoreOption" style="height: 330px; width: 400px" />
        <div class="table-box">
          <el-table :data="table3" height="276">
            <el-table-column label="治理排名" prop="column1" align="center"></el-table-column>
            <el-table-column label="公司名称" prop="column2" align="center"></el-table-column>
            <el-table-column label="治理率" prop="column3" align="center"></el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import upImg from '../../../assets/img/brainDecision/main_icon_rise_normal.png.png';
import downImg from '../../../assets/img/brainDecision/main_icon_fall_normal.png.png';

export default {
  data() {
    return {
      upImg,
      downImg,
      afterData: [
        { name: '浙江中亿豪科技有限公司', value: [20000, 10000, 20000], n: [-5.62, -5.62, 5.62] },
        { name: '威马汽车制造温州有限公司', value: [20000, 40000, 30000], n: [5.62, -5.62, -5.62] },
        { name: '浙江曼瑞德舒适系统有限公司', value: [10000, 30000, 50000], n: [5.62, 5.62, -5.62] },
        { name: '温州宏丰特种材料有限公司', value: [20000, 4000, 10000], n: [-5.62, -5.62, 5.62] },
        { name: '温州金马文具用品制造有限公司', value: [20000, 10000, 50000], n: [5.62, -5.62, 5.62] },
      ],
      beforeData: [
        { name: '浙江中亿豪科技有限公司', value: [20000, 50000, 20000], n: [5.62, -5.62, 5.62] },
        { name: '威马汽车制造温州有限公司', value: [20000, 50000, 30000], n: [5.62, -5.62, 5.62] },
        { name: '浙江曼瑞德舒适系统有限公司', value: [20000, 30000, 30000], n: [5.62, -5.62, 5.62] },
        { name: '温州宏丰特种材料有限公司', value: [20000, 40000, 10000], n: [5.62, -5.62, 5.62] },
        { name: '温州金马文具用品制造有限公司', value: [50000, 40000, 30000], n: [5.62, -5.62, 5.62] },
      ],
      table1: [
        { column1: '3.56', column2: '13.56', column3: '200.03', column4: '24.33', column5: '3.56', column6: '13.45', column7: '233.33', column8: '25.66' },
        { column1: '3.56', column2: '13.56', column3: '200.03', column4: '24.33', column5: '3.56', column6: '13.45', column7: '233.33', column8: '25.66' },
        { column1: '3.56', column2: '13.56', column3: '200.03', column4: '24.33', column5: '3.56', column6: '13.45', column7: '233.33', column8: '25.66' },
        { column1: '3.56', column2: '13.56', column3: '200.03', column4: '24.33', column5: '3.56', column6: '13.45', column7: '233.33', column8: '25.66' },
        { column1: '3.56', column2: '13.56', column3: '200.03', column4: '24.33', column5: '3.56', column6: '13.45', column7: '233.33', column8: '25.66' },
      ],
      table2: [
        {
          column1: '温州市博弘电力有限公司',
          column2: '3.56',
          column3: '13.56',
          column4: '200.23',
          column5: '24.33',
          column6: '3.56',
          column7: '13.56',
          column8: '200.23',
          column9: '24.33',
        },
      ],
      table3: [
        { column1: '1', column2: '博弘电力', column3: '95%' },
        { column1: '2', column2: '怀远电器', column3: '93%' },
        { column1: '3', column2: '巴啦啦能量', column3: '85%' },
        { column1: '4', column2: '迪迦设备', column3: '82%' },
        { column1: '5', column2: '赛博坦物流', column3: '76%' },
        { column1: '6', column2: '赛博坦物流', column3: '73%' },
      ],
    };
  },
  methods: {
    getAfterBeforeOption(data, inverse) {
      let total = 0;
      data.forEach((v, i) => {
        total += v.value.reduce(function (a, b) {
          return a + b;
        }, 0);
      });
      return {
        color: ['#7325DA', '#245ADA', '#14C53E', '#ED792C', '#E22132', '#6A96DC'],
        grid: {
          top: 25,
          bottom: 10,
        },
        tooltip: {
          show: true,
          backgroundColor: 'rgba(0,0,0,0.65)',
          formatter: function (p) {
            let text = '';
            data.forEach((v) => {
              if (v.n[p.dataIndex] > 0) {
                text += `<div class='tooltip-text fl-c-b'>${v.name}: ${v.value[p.dataIndex]}万  <span class='green'>${
                  v.n[p.dataIndex]
                }%<img src="${upImg}" alt=""></span></div>`;
              } else {
                text += `<div class='tooltip-text fl-c-b'>${v.name}: ${v.value[p.dataIndex]}万  <span class='red'>${
                  v.n[p.dataIndex]
                }%<img src="${downImg}" alt=""></span></div>`;
              }
            });
            return text;
          },
        },
        xAxis: {
          show: false,
          inverse, //是否向左
          interval: 16, // X间隔
          max: total / 1.5,
        },
        yAxis: [
          {
            data: [],
            max: 'dataMax',
            axisLine: {
              show: false,
            },
          },
        ],
        series: data.map((v) => {
          return {
            type: 'bar',
            name: v.name,
            stack: '2',
            barWidth: 24,
            data: v.value,
          };
        }),
      };
    },
  },
  computed: {
    beforeChartOption() {
      console.log(this.afterTotal);
      let option = this.getAfterBeforeOption(this.beforeData, true);
      option.grid.right = 0;
      option.title = {
        text: '赋能前',
        top: 'top',
        right: 0,
        textStyle: {
          fontSize: 14,
          color: '#666',
          fontWeight: 600,
        },
      };
      return option;
    },
    afterChartOption() {
      let option = this.getAfterBeforeOption(this.afterData, false);
      option.grid.left = 0;
      option.title = {
        text: '赋能后',
        top: 'top',
        left: 0,
        textStyle: {
          fontSize: 14,
          color: '#666',
          fontWeight: 600,
        },
      };
      return option;
    },
    growthOption() {
      return {
        // color: [' #0E459C', ' #6DC0DF'],
        grid: {
          top: 0,
          bottom: 0,
          containLabel: true,
        },
        tooltip: {
          show: true,
          backgroundColor: 'rgba(0,0,0,0.65)',
        },
        xAxis: {
          max: 100,
          name: '(%)',
          nameTextStyle: {
            color: '#666',
            lineHeight: 50,
            padding: [25, 0, 0, 0],
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#B4B4BB',
            },
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: true,
          },
          axisLabel: {
            color: '#666',
          },
        },
        yAxis: [
          {
            data: ['营业收入增长率', '净利润增长率'],
            offset: 25,
            //y轴线样式
            axisLine: {
              show: true,
              lineStyle: {
                color: '#B4B4BB',
              },
            },
            //设置与x轴平行的线(分割线)不显示
            splitLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: '#666',
            },
          },
        ],
        series: [
          {
            type: 'bar',
            barWidth: '30',
            data: [{ value: 65 }, { value: 90 }],
            itemStyle: {
              normal: {
                color: function (params) {
                  // build a color map as your need.
                  var colorList = [' #6DC0DF', '#0E459C'];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      };
    },
    scoreOption() {
      var dataname = ['污水排放', '噪音', '辐射', '固体废弃物', '废气排放'];
      var datamax = [10, 10, 10, 10, 10];
      var datavaule = [9, 6, 10, 5, 9];
      var datavaule1 = [6, 8, 10, 6, 5];

      var indicator = [];
      for (var i = 0; i < dataname.length; i++) {
        indicator.push({
          name: dataname[i],
          max: datamax[i],
        });
      }

      return {
        tooltip: {
          show: true,
          trigger: 'item',
        },
        title: {
          text: '博弘电力',
          bottom: '0',
          left: 'center',
          textStyle: {
            fontSize: 18,
            color: '#333',
            fontWeight: 600,
          },
        },
        legend: {
          left: 0,
          orient: 'vertical',
          data: ['产生', '治理'],
          itemGap: 10,
          textStyle: {
            fontSize: '14',
            color: '#666',
          },
        },
        radar: {
          center: ['50%', '50%'],
          radius: 119,
          startAngle: 90,
          splitNumber: 3,
          splitArea: {
            areaStyle: {
              color: ['transparent'],
            },
          },
          axisLabel: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#eee',
            },
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: '#eee',
            },
          },
          name: {
            formatter: '{value}',
            textStyle: {
              color: '#666',
              fontSize: 14,
            },
          },
          indicator: indicator,
        },

        series: [
          {
            name: '产生',
            type: 'radar',
            symbol: 'circle',
            symbolSize: 8,

            itemStyle: {
              normal: {
                color: '#F1B32E',
              },
            },
            lineStyle: {
              normal: {
                color: '#F1B32E',
                width: 2,
              },
            },
            data: [datavaule],
          },
          {
            name: '治理',
            type: 'radar',
            symbol: 'circle',
            symbolSize: 6,

            itemStyle: {
              normal: {
                color: '#0E459C',
              },
            },
            lineStyle: {
              normal: {
                color: '#0E459C',
                width: 2,
              },
            },
            data: [datavaule1],
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-table .cell {
  /*text-align: center;*/
  white-space: pre-line; /*保留换行符*/
}
/deep/ .tooltip-text {
  font-size: 12px;
  color: #fff;
  img {
    width: 12px;
    height: 12px;
  }
}
.BeforeAfter {
  height: 150px;
  margin-top: 24px;
  width: calc(100% - 369px);
  .before-after-chart {
    width: calc((100% - 120px) / 2);
    height: 100%;
  }
  .compared-item {
    background: #eff7ff;
    border: 1px solid #6a96dc;
    width: 104px;
    height: 36px;
    line-height: 36px;
    text-align: center;
    margin: 0 8px 4px;
    font-size: 14px;
    color: #6a96dc;
  }

  .compared-label {
    margin-top: 25px;
  }
}
.compared-info {
  margin-left: 60px;
  width: 309px;
}
.compared-text {
  color: #606266;
}
.table-title {
  padding-left: 16px;
  color: #000000;
  position: relative;
  margin-bottom: 10px;
  &::after {
    content: '';
    position: absolute;
    top: 7px;
    left: 0;
    width: 8px;
    height: 8px;
    background: #4393f8;
  }
}
.scale-box {
  margin-top: 35px;
  margin-bottom: 25px;
  .scale-tiem {
    width: 114px;
    height: 114px;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .scale-lable {
      margin-top: 5px;
      opacity: 0.8;
    }
    &:nth-of-type(1) {
      background: #4393f8;
    }
    &:nth-of-type(2) {
      background: #f1b32e;
      margin: 0 20px;
    }
    &:nth-of-type(3) {
      background: #e22132;
    }
  }
  .business-box {
    margin-left: 140px;
    color: #333333;
    width: 459px;
    .business-header {
      height: 52px;
      line-height: 52px;
      color: #151515;
      padding-left: 30px;
      background-color: rgba(14, 69, 156, 0.1);
      .bold {
        color: #333333;
      }
    }
    .business-body {
      background-color: rgba(14, 69, 156, 0.05);
      padding: 0 30px;
      height: 132px;
      overflow: hidden;
      .business-item {
        position: relative;
        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 185px;
          width: 1px;
          height: 24px;
          opacity: 0.15;
          background: #0e459c;
        }
        &:nth-of-type(1) {
          margin-top: 23px;
          margin-bottom: 32px;
        }
      }
    }
  }
}
.sewage-box {
  padding-top: 40px;
  padding-bottom: 9px;
  .table-box {
    width: 431px;
    margin-left: 150px;
  }
}
</style>
