<template>
  <div class="wrapper enterprise-operation-analysis">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/EnterpriseService' }">智能诊断</el-breadcrumb-item>
      <el-breadcrumb-item>企业运营分析</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="header fl-c-b mb16">
      <div class="fl-box">
        <el-tabs class="fl-box" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane label="能耗分析" name="consumption"></el-tab-pane>
          <el-tab-pane label="经营分析" name="operating"></el-tab-pane>
        </el-tabs>
      </div>
      <div class="fl-c-c">
        <label>时间</label>
        <el-select v-model="year">
          <el-option label="2021全年" value="2021"></el-option>
          <el-option label="2020全年" value="2020"></el-option>
          <el-option label="2019全年" value="2019"></el-option>
          <el-option label="2018全年" value="2018"></el-option>
          <el-option label="2017全年" value="2017"></el-option>
        </el-select>
      </div>
    </div>
    <template v-if="activeName == 'consumption'">
      <consumption></consumption>
    </template>
    <template v-else>
      <operating></operating>
    </template>
  </div>
</template>

<script>
import operating from './operating';
import consumption from './consumption';
export default {
  data() {
    return {
      activeName: 'consumption',
      path: 'consumption',
      year: '2019',
      //默认
      table: [
        { type: '园区材料', name: '投资协议模板', url: '', channel: '投促局', need: 1 },
        { type: '园区材料', name: '投资协议模板', url: '', channel: '投促局', need: 1 },
        { type: '园区材料', name: '投资协议模板', url: '', channel: '投促局', need: 1 },
        { type: '园区材料', name: '投资协议模板', url: '', channel: '投促局', need: 1 },
        { type: '园区材料', name: '投资协议模板', url: '', channel: '投促局', need: 1 },
      ],
    };
  },
  components: {
    consumption,
    operating,
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
      if (tab.name === 'consumption') {
        this.path = 'consumption';
      } else if (tab.name === 'operating') {
        this.path = 'operating';
      }
    },
    toPage() {
      this.$router.push({ path: this.path });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-tabs__header {
  margin: 0 !important;
}

/deep/ .el-tabs__active-bar {
  height: 0 !important;
  background-color: transparent !important;
}

/deep/ .el-tabs__nav-wrap {
  margin: 0;
  &::after {
    height: 0;
  }
}

/deep/ #tab-consumption {
  width: 196px;
  height: 76px;
  font-family: MicrosoftYaHei-Bold;
  font-size: 26px;
  letter-spacing: 0;
  line-height: 76px;
  text-align: center;
  margin-right: 5px;
  color: #666;
  &::after {
    content: ''; /* To generate the box */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: #e5e7ec;
    transform: skew(-8deg);
    margin-left: -5px;
  }
}
/deep/ #tab-operating {
  width: 196px;
  height: 76px;
  font-family: MicrosoftYaHei-Bold;
  font-size: 26px;
  letter-spacing: 0;
  line-height: 76px;
  text-align: center;
  color: #666;
  margin-right: 8px;
  &::after {
    content: ''; /* To generate the box */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    background: #e5e7ec;
    transform: skew(-8deg);
  }
}
/deep/ .el-tabs__item.is-active {
  color: #ffffff !important;
  font-weight: 600;
  &::after {
    background-image: linear-gradient(270deg, #024a8f 0%, #4393f8 100%) !important;
  }
}

.header {
  width: 100%;
  height: 76px;
  padding-right: 20px;
  background: #ffffff;
  label {
    color: #666666;
    margin-right: 12px;
  }
}
</style>
<style lang="scss">
.enterprise-operation-analysis {
  .h279 {
    height: 279px;
  }
  .h112 {
    height: 112px;
  }
  .mb30 {
    margin-bottom: 30px;
  }
  .mb4 {
    margin-bottom: 4px;
  }
  .mt16 {
    margin-top: 16px;
  }
  .mt18 {
    margin-top: 18px;
  }
  .fs21{
    font-size: 21px;
  }
  .conclusion,
  .advice {
    font-size: 14px;
    color: #000000;
    line-height: 20px;
    position: relative;
    display: inline-block;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0.4;
      background: #255ada;
      height: 4px;
      width: 100%;
    }
  }
  .advice {
    &::after {
      background: #e22132;
    }
  }
  .session {
    padding: 0 20px 21px;
    background-color: #fff;
    .title {
      font-size: 26px;
      color: #333333;
      line-height: 74px;
      font-weight: 600;
      position: relative;
      padding-left: 14px;
      &::after {
        content: '';
        position: absolute;
        top: 28px;
        left: 0;
        width: 4px;
        height: 20px;
        background: #4393f8;
        border-radius: 3px;
      }
    }
  }
}
</style>
